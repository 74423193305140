export const pricing_options = [
  {
    title: "Standard",
    description: "Kickstart your community.",
    image: "/CareerIcons/operational-black.png",
    features: [
      "Hosted on mynexusapp/{YourDomain}",
      "Up to 10 users",
      "Access to basic social media platform features",
      "Email support",
      "Community forums access",
    ],
  },
  {
    title: "Advanced",
    description: "Keep your brand growing.",
    image: "/CareerIcons/engineering-black.png",
    features: [
      "Can be hosted on your own domain",
      "Up to 100 users",
      "Advanced social media platform features",
      "Priority email support",
      "Analytics and reporting tools",
      "Customizable user profiles",
    ],
  },
  {
    title: "Branded",
    description: "Fully Whitelabeled. Fully Yours.",
    image: "/CareerIcons/marketing-black.png",
    features: [
      "Unlimited users",
      "Hosted on your own domain",
      "Fully whitelabeled platform",
      "24/7 priority support",
      "Advanced analytics and user segmentation",
      "Custom branding and design options",
      "API access for custom integrations",
    ],
  },
  {
    title: "Custom",
    description: "Completely Customized, for you.",
    image: "/CareerIcons/marketing-black.png",
    features: [
      "Talk to sales for custom solutions",
      "Everything from the Client Portal to the Admin Portal can be created for you",
      "Fully custom feature set",
      "Dedicated account manager",
      "End-to-end encryption for data security",
      "Custom integrations with existing systems",
      "Scalable infrastructure to grow with your needs",
    ],
  },
];
