import React from "react";
import LandingNavbar from "../../layout/LandingNavbar/LandingNavbar";
import HomeComponent from "../../components/LandingComponents/HomePage/HomeComponent";

const HomePage = () => {
  return (
    <div>
      <LandingNavbar childComponent={<HomeComponent />} activeText="Home" />
    </div>
  );
};

export default HomePage;
