import React from "react";
import LandingNavbar from "../../layout/LandingNavbar/LandingNavbar";
import EnterpriseComponent from "../../components/LandingComponents/EnterprisePage/EnterpriseComponent";

const EnterprisePage = () => {
  return (
    <div>
      <LandingNavbar
        childComponent={<EnterpriseComponent />}
        activeText="Enterprise"
      />
    </div>
  );
};

export default EnterprisePage;
