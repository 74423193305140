import React from "react";
import LandingNavbar from "../../layout/LandingNavbar/LandingNavbar";
import ProductComponent from "../../components/LandingComponents/ProductPage/ProductComponent";

const ProductPage = () => {
  return (
    <div>
      <LandingNavbar
        childComponent={<ProductComponent />}
        activeText="Product"
      />
    </div>
  );
};

export default ProductPage;
