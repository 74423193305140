import React, { useEffect } from "react";
import { useState } from "react";

import styles from "./pricing.module.css";

import LandingFooter from "../../../layout/LandingFooter/footer";
import CheckmarkIcon from "../../../assets/svg/CheckIcon";
import { pricing_options } from "../../../data/pricing_options";

const PricingComponent = () => {
  return (
    <>
      <div className={styles.landing_page}>
        <div className={styles.page_header}>
          <div className={styles.page_titles}>
            <p className={styles.page_title}>Pricing</p>
            <p className={styles.page_description}>
              Our mission is to empower founders, fueling their journey from
              ideation to impact. At the core of our philosophy lies the
              celebration of both innovation and invention—two forces that drive
              the advancement of industries and societies.
            </p>
          </div>
        </div>
        <div className={styles.page_line_break}></div>
        <PricingTiles pricingOptions={pricing_options} />
      </div>
      <LandingFooter />
    </>
  );
};

export const PricingTiles = ({ pricingOptions }) => {
  return (
    <div className={styles.career_options}>
      {pricingOptions.map((option, index) => (
        <div key={index} className={styles.pricing_tile}>
          {/* <img
          src={option.image}
          className={styles.career_image}
          alt={option.title}
        ></img> */}
          <div className={styles.pricing_tile_top}>
            <p className={styles.career_title}>{option.title}</p>
            <p className={styles.career_subtitle}>{option.description}</p>
            <div className={styles.details_layout}>
              <p className={styles.details_word}>DETAILS</p>
              <div className={styles.details_line}></div>
            </div>
            <div className={styles.pricing_features}>
              {option.features.map((feature, index) => (
                <div key={index} className={styles.pricing_feature}>
                  <CheckmarkIcon />
                  <div className={styles.pricing_feature_text}>{feature}</div>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.cta_button}>Choose</div>
        </div>
      ))}
    </div>
  );
};

export default PricingComponent;
