import styles from "./landingnavbar.module.css";
import { useNavigate } from "react-router";

import HoverFlickerText from "../../components/LandingComponents/Global/HoverFlickerText";

export default function NavbarMenu(props) {
  const navigate = useNavigate();

  const navItems = [
    { name: "Home", path: "/" },
    { name: "Portfolio", path: "/portfolio" },
    { name: "Mission", path: "/mission" },
    { name: "Careers", path: "/careers" },
    // { name: "FAQ", path: "/frequently-asked-questions" },
    // { name: "Apply", path: "/apply" },
  ];

  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <div className={styles.navbar_menu}>
      <div className={styles.navbar_menu_content}>
        {navItems.map((item) => (
          <HoverFlickerText
            text={item.name}
            className={styles.menu_item_mobile}
            onClick={() => navigateTo(item.path)}
          />
        ))}
      </div>
    </div>
  );
}
//navmenu-arrow.png
