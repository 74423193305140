import React from "react";
import { useState, useEffect } from "react";
import { useRef } from "react";

import styles from "./home.module.css";
import LandingFooter from "../../../layout/LandingFooter/footer";
import { useNavigate } from "react-router";

import lottie from "lottie-web";
import { defineElement } from "@lordicon/element";

import { useMediaQuery } from "@material-ui/core";

import { PricingTiles } from "../PricingPage/PricingComponent";
import { pricing_options } from "../../../data/pricing_options";

defineElement(lottie.loadAnimation);

const SectionMarker = ({ title, color }) => {
  const starpoint =
    color === "white" ? "/starpoint-white.png" : "/starpoint-black.png";

  const titleStyle =
    color === "white" ? styles.marker_title : styles.marker_title_black;

  return (
    <div className={styles.section_marker}>
      <img className={styles.star_icon} src={starpoint}></img>
      <p className={titleStyle}>{title}</p>
    </div>
  );
};

const HomeComponent = () => {
  const navigate = useNavigate();

  function handleCompanyCode() {
    navigate("/enter-company-code");
  }

  function handleApply() {
    navigate("/apply");
  }

  function handlePortfolio() {
    navigate("/portfolio");
  }
  function handleCreate() {
    window.location.href = "https://create.mynexusapp.com/";
  }

  const isDesktop = useMediaQuery("(min-width: 1024px)");

  // Ref for the container to hold the animation
  const clockContainer = useRef(null);
  const targetContainer = useRef(null);
  const search = useRef(null);

  useEffect(() => {
    if (clockContainer.current) {
      const animation = lottie.loadAnimation({
        container: clockContainer.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: "/AnimatedIcons/clock-icon.json",
      });

      return () => animation.destroy();
    }
  }, []);

  useEffect(() => {
    if (targetContainer.current) {
      const animation = lottie.loadAnimation({
        container: targetContainer.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: "/AnimatedIcons/target-enlarge.json",
      });

      return () => animation.destroy();
    }
  }, []);

  useEffect(() => {
    if (search.current) {
      const animation = lottie.loadAnimation({
        container: search.current,
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: "/AnimatedIcons/search-icon.json",
      });

      return () => animation.destroy();
    }
  }, []);

  return (
    <div className={styles.home_page_landing}>
      <div className={styles.top_header_section}>
        <img
          src="/LaserCloudBGSquare.jpeg"
          className={styles.top_header_image}
        ></img>
      </div>
      <div className={styles.shadow_background}>
        <p className={styles.landing_title}>Affiliate Marketing Ltd.</p>
        {/* <p className={styles.landing_title}></p> */}
        <div className={styles.landing_main_cta} onClick={handleCompanyCode}>
          Replace Your Income
        </div>
      </div>
      {/* <LandingFooter /> */}
    </div>
  );
};

export function EnterpriseTiles() {
  return (
    <div className={styles.white_section}>
      <div className={styles.row_two}>
        <div className={styles.row_item_image_light}>
          <img
            src="/WebScreenshots/NexusFeedPage.png"
            className={styles.web_screenshot_row_image}
          ></img>
          <div className={styles.row_text_boxes}>
            <p className={styles.row_text_title_black}>Personalized Profiles</p>
            <p className={styles.row_text_subtitle_black}>
              Allow your users to connect with you, as the catalyst.
            </p>
          </div>
        </div>
        <div className={styles.row_item_gray}>
          <div className={styles.row_item_box_text_image}>
            <p className={styles.row_text_title}>Community-Driven Connective</p>
            <p className={styles.row_text_subtitle}>
              Position your brand as the center of your community to create a
              lasting impression with your audience.
            </p>
          </div>
          <img
            src="/TransparenWhiteCubes.png"
            className={styles.row_item_hand_image}
          ></img>
        </div>
      </div>
      <div className={styles.row_three}>
        <div className={styles.row_item}>
          <img src="/tile-image-1.jpeg" className={styles.row_image}></img>
          <div className={styles.shadow_background}>
            <div className={styles.row_text_boxes}>
              <p className={styles.row_text_title}>Seamless Creation</p>
              <p className={styles.row_text_subtitle}>
                Our platform ensures that the act of creation itself is as
                seamless as the connections it forges, allowing you to focus on
                what truly matters—building a community that resonates with your
                vision.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.row_item}>
          <img src="/tile-image-2.jpeg" className={styles.row_image}></img>
          <div className={styles.shadow_background}>
            <div className={styles.row_text_boxes}>
              <p className={styles.row_text_title}>Full Control</p>
              <p className={styles.row_text_subtitle}>
                In this realm of unmatched autonomy, every choice you make lays
                the foundation for deeper, more meaningful connections. With the
                reins firmly in your hands, watch as your brand becomes a beacon
                for those who share your vision and values
              </p>
            </div>
          </div>
        </div>
        <div className={styles.row_item}>
          <img src="/tile-image-3.jpg" className={styles.row_image}></img>
          <div className={styles.shadow_background}>
            <div className={styles.row_text_boxes}>
              <p className={styles.row_text_title}>Reliable and Secure</p>
              <p className={styles.row_text_subtitle}>
                In a world where reliability and security are paramount, our
                commitment to these principles is unwavering. Experience the
                peace of mind that comes from knowing every interaction is
                protected, fostering a safe space for connections to flourish.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <div className={styles.row_four}>
    <div className={styles.row_item}></div>
  </div> */}
    </div>
  );
}

export default HomeComponent;
