import React, { useEffect } from "react";
import { useState } from "react";

import styles from "./enterprise.module.css";

import LandingFooter from "../../../layout/LandingFooter/footer";
import { useMediaQuery } from "@material-ui/core";
import CheckmarkIcon from "../../../assets/svg/CheckIcon";
import { EnterpriseTiles } from "../HomePage/HomeComponent";

const EnterpriseComponent = () => {
  //BuildingStockVideo.mp4
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  const enterprise_value_prop = [
    "Customizable Brand Platform",
    "Exclusive App & Web Presence",
    "Dedicated Support",
    "Advanced Engagement Analytics",
    "Robust Security Features",
    "Scalable Infrastructure",
    "Integrated Marketing Tools",
    "Easy Content Management",
    "Third-party Integrations",
    "Real-time Community Communication",
    "Monetization Options",
    "Continuous Feature Updates",
  ];

  const enterprise_industry_examples = [
    // {
    //   industry: "Fitness & Wellness",
    //   description:
    //     "Enables fitness enthusiasts to share journeys, access workout plans, and interact with experts.",
    // },
    // {
    //   industry: "Education",
    //   description:
    //     "Fosters a learning community, sharing resources, hosting lectures, and facilitating student-educator communication.",
    // },
    // {
    //   industry: "Healthcare",
    //   description:
    //     "Provides a secure platform for patient support, health tips, and direct patient-provider communication.",
    // },
    {
      industry: "Fashion",
      description:
        "Showcases fashion collections, launches products, and builds a community for brand interaction.",
    },
    {
      industry: "Gaming",
      description:
        "Creates a gamer community for discussing games, sharing strategies, and receiving developer updates.",
    },
    // {
    //   industry: "Hospitality",
    //   description:
    //     "Shares travel tips, exclusive deals, and fosters a traveler community for sharing experiences.",
    // },
    {
      industry: "Real Estate",
      description:
        "Offers a platform for listing browsing, agent connection, and virtual tours.",
    },
    {
      industry: "Finance",
      description:
        "Provides financial education, market insights, and fosters a community around financial wellness.",
    },
    {
      industry: "Technology",
      description:
        "Launches tech products, shares news, and builds a tech enthusiast community for feedback.",
    },
    {
      industry: "Food & Beverage",
      description:
        "Creates a food culture community, sharing recipes, cooking tips, and dining experiences.",
    },
    {
      industry: "Non-Profit",
      description:
        "Builds a supporter community, shares initiative updates, and organizes fundraising events.",
    },
    {
      industry: "Sports",
      description:
        "Enables fans to follow games, share experiences, and engage with teams and athletes.",
    },
  ];

  return (
    <>
      <div className={styles.landing_page}>
        {isDesktop ? (
          <div className={styles.video_container}>
            {/* <p className={styles.video_title}>Enterprise Business Solutions</p> */}
            <p className={styles.video_title}>Enterprise</p>
            <video
              className={styles.nexusVideo}
              autoPlay
              muted
              loop
              playsInline
            >
              <source src="/Videos/Untitled.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        ) : (
          <></>
        )}
        <div className={styles.page_header}>
          <div className={styles.page_titles}>
            <p className={styles.page_title}>Customize Your Solution</p>
            <p className={styles.page_description}>
              Build the perfect platform for your business.
            </p>
            {/* <div className={styles.header_content_bar}>
              <div className={styles.banner_side_content}></div>
              <img
                src="/HandHoldingPhoneUpright.png"
                className={styles.item_hand_image}
              ></img>
            </div> */}
            <div className={styles.white_benefits_section}>
              <div className={styles.benefit_top}>
                {/* <p className={styles.benefit_title}>
                  Nexus offers a simple solution allowing brands to launch a
                  customized social media platforms in minutes. Create
                  community, build interaction, and elevate your brand's digital
                  presence in minutes with Nexus.
                </p> */}
              </div>
              <div className={styles.benefit_section}>
                <div className={styles.benefit_section_divider}></div>
                <div className={styles.benefit_section_item}>
                  <div className={styles.benefit_section_item_left}>
                    <img
                      src="BenefitImages/customize-image.jpeg"
                      className={styles.benefit_section_image}
                    ></img>
                    <p className={styles.benefit_description_title}>
                      Customize
                    </p>
                  </div>
                  <p className={styles.benefit_description}>
                    Unlock the power of personalization with Nexus, where
                    customizing your brand's social media platform is as simple
                    as a few clicks.
                  </p>
                </div>
                <div className={styles.benefit_section_divider}></div>
                <div className={styles.benefit_section_item}>
                  <div className={styles.benefit_section_item_left}>
                    <img
                      src="BenefitImages/configure-image.jpeg"
                      className={styles.benefit_section_image}
                    ></img>
                    <p className={styles.benefit_description_title}>
                      Configure
                    </p>
                  </div>
                  <p className={styles.benefit_description}>
                    Effortlessly configure your platform's settings with Nexus,
                    ensuring your social media space perfectly aligns with your
                    brand's goals.
                  </p>
                </div>
                <div className={styles.benefit_section_divider}></div>
                <div className={styles.benefit_section_item}>
                  <div className={styles.benefit_section_item_left}>
                    <img
                      src="BenefitImages/connect-image.jpeg"
                      className={styles.benefit_section_image}
                    ></img>
                    <p className={styles.benefit_description_title}>Connect</p>
                  </div>
                  <p className={styles.benefit_description}>
                    Deepen your connection with your audience through a tailored
                    social media experience that engages and inspires community
                    interaction.
                  </p>
                </div>
                <div className={styles.benefit_section_divider}></div>
                <div className={styles.benefit_section_item}>
                  <div className={styles.benefit_section_item_left}>
                    <img
                      src="BenefitImages/control-image.webp"
                      className={styles.benefit_section_image}
                    ></img>
                    <p className={styles.benefit_description_title}>Control</p>
                  </div>
                  <p className={styles.benefit_description}>
                    Take full control of your digital presence with Nexus,
                    creating a secure and exclusive environment where your
                    community can thrive.
                  </p>
                </div>
                <div className={styles.benefit_section_divider}></div>
              </div>
            </div>
          </div>
          <img
            src="/BlackSquareBackgroundTilted.png"
            className={styles.black_square_background}
          ></img>
        </div>
        <div className={styles.page_body}>
          <div className={styles.page_body_titles}>
            <p className={styles.body_title}>Customize Your Solution</p>
            <p className={styles.body_description}>
              Build the perfect platform for your business.
            </p>
          </div>
          <div className={styles.enterprise_layout}>
            {enterprise_value_prop.map((feature, index) => (
              <div key={index} className={styles.enterprise_prop}>
                <CheckmarkIcon />
                <div className={styles.enterprise_prop_text}>{feature}</div>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.gray_section}>
          <p className={styles.industry_title}>
            Learn how Nexus can support your insdutry
          </p>
          <p className={styles.industry_subtitle}>
            Whether you’re an event planner, interior designer, talent
            management agency, or concierge experience, the most sophisticated
            businesses in the industry choose Moxo to power their app.
          </p>
          <div className={styles.enterprise_industry_examples_layout}>
            {enterprise_industry_examples.map((industry, index) => (
              <div key={index} className={styles.indstury}>
                <img
                  className={styles.industry_image}
                  src="https://images.unsplash.com/photo-1486406146926-c627a92ad1ab?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8YnVzaW5lc3MlMjBmaW5hbmNlfGVufDB8fDB8fHww"
                ></img>
                <div className={styles.industry_text}>{industry.industry}</div>
                <div className={styles.industry_description_text}>
                  {industry.description}
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* <div className={styles.page_line_break}></div> */}
        <EnterpriseTiles />
      </div>
      <LandingFooter />
    </>
  );
};

export default EnterpriseComponent;
