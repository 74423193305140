import styles from "./landingnavbar.module.css";
import { useNavigate } from "react-router";

import { useState } from "react";

import HoverFlickerText from "../../components/LandingComponents/Global/HoverFlickerText";
import InitFlickerEffectText from "../../components/LandingComponents/Global/InitFlickerText";
import NavbarMenu from "./NavbarMenu";

import { useMediaQuery } from "@material-ui/core";

const Navbar = () => {
  const navigate = useNavigate();

  const navItems = [
    { name: "Home", path: "/" },
    // { name: "Pricing", path: "/pricing" },
    // { name: "Product", path: "/product" },
    // { name: "Enterprise", path: "/enterprise" },

    // { name: "FAQ", path: "/frequently-asked-questions" },
    // { name: "Apply", path: "/apply" },
  ];

  const navigateTo = (path) => {
    navigate(path);
  };

  function handleCompanyCode() {
    navigate("/enter-company-code");
  }

  function goHome() {
    navigate("/");
  }

  return (
    <div className={styles.landing_navbar}>
      <div className={styles.menu_items}>
        <div className={styles.logo_box} onClick={goHome}>
          <img
            src="/AffiliateMarketingLogo.png"
            alt="logo"
            className={styles.logo}
          ></img>
        </div>
        <div className={styles.menu_options}>
          {navItems.map((item) => (
            <p
              key={item.name}
              className={styles.menu_item}
              onClick={() => navigateTo(item.path)}
            >
              {item.name}
            </p>
          ))}
        </div>
      </div>
      <div className={styles.button_options}>
        <div className={styles.login_button} onClick={handleCompanyCode}>
          <p>Enter Company Code</p>
        </div>
      </div>
    </div>
  );
};

const MobileNavbar = ({ toggleMenu }) => {
  const navigate = useNavigate();

  const navItems = [
    { name: "Home", path: "/" },
    { name: "Portfolio", path: "/portfolio" },
    { name: "Mission", path: "/mission" },
    { name: "Careers", path: "/careers" },
    // { name: "FAQ", path: "/frequently-asked-questions" },
    { name: "Apply", path: "/apply" },
  ];

  const navigateTo = (path) => {
    navigate(path);
  };

  function handleLogin() {
    navigate("/partner-login");
  }

  function goHome() {
    navigate("/");
  }

  return (
    <div className={styles.landing_navbar}>
      <div className={styles.menu_items}>
        <div className={styles.logo_box} onClick={goHome}>
          <img
            src="/AffiliateMarketingLogo.png"
            alt="logo"
            className={styles.mobile_logo}
          ></img>
        </div>
        <div className={styles.menu_options}></div>
      </div>
      <div className={styles.hamburger}>
        <input
          className={styles.checkbox}
          type="checkbox"
          onClick={toggleMenu}
        />
        <svg fill="none" viewBox="0 0 50 50" height="50" width="50">
          <path
            className={`${styles.line} ${styles.lineTop}`}
            strokeLinecap="round"
            strokeWidth="4"
            stroke="black"
            d="M6 11L44 11"
          />
          <path
            className={`${styles.line} ${styles.lineMid}`}
            strokeLinecap="round"
            strokeWidth="4"
            stroke="black"
            d="M6 24H43"
          />
          <path
            className={`${styles.line} ${styles.lineBottom}`}
            strokeLinecap="round"
            strokeWidth="4"
            stroke="black"
            d="M6 37H43"
          />
        </svg>
      </div>
    </div>
  );
};

export default function LandingNavbar(props) {
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <>
      {/* <Navbar /> */}
      {isDesktop ? <Navbar /> : <MobileNavbar toggleMenu={toggleMenu} />}

      {isMenuOpen ? (
        <NavbarMenu />
      ) : (
        <div className={styles.landing_page_layout}>{props.childComponent}</div>
      )}
    </>
  );
}

//NavbarMenu
