import React from "react";
import LandingNavbar from "../../layout/LandingNavbar/LandingNavbar";
import PricingComponent from "../../components/LandingComponents/PricingPage/PricingComponent";

const PricingPage = () => {
  return (
    <div>
      <LandingNavbar
        childComponent={<PricingComponent />}
        activeText="Pricing"
      />
    </div>
  );
};

export default PricingPage;
