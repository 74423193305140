import React from "react";
import { useState, useContext } from "react";

import styles from "./auth.module.css";
import { useEffect } from "react";
// import { AuthContext } from "../../../context/AuthContext";
// import { CompanyContext } from "../../../context/CompanyContext";
import { useNavigate } from "react-router";
// import CircularLoader from "../CircularLoader/CircularLoader";
// import SignUpComponentSidebar from "./SignUpComponentSidebar";

const EnterCompanyCode = () => {
  const navigate = useNavigate();
  // const { signIn } = useContext(AuthContext);
  // const { companyData } = useContext(CompanyContext);
  const [showSidebar, setShowSidebar] = useState(window.innerWidth > 600);

  useEffect(() => {
    const handleResize = () => {
      setShowSidebar(window.innerWidth > 600);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState(null);

  function handleSignUp() {
    navigate("/signup");
  }

  function handleForgotPassword() {
    navigate("/enter-company-code");
  }

  async function handleSubmit() {
    // try {
    //   await signIn(email, password);
    //   navigate(`/${companyData.company_domain}/community`);
    // } catch (error) {
    //   console.error("Login failed:", error);
    //   setError(error.response?.data?.message || "Invalid Credentials");
    // }
  }

  const canContinue = email !== "";
  //rgb(243, 201, 60)

  return (
    <div className={styles.authentication_page}>
      <div className={styles.authentication_layout}>
        <div className={styles.component_side_layout}>
          <div className={styles.sidebar}>
            <img
              alt="logo"
              src="/AffiliateMarketingLogoBlack.png"
              className={styles.logo_icon}
            ></img>
            <p className={styles.signin_page_title}>
              Enter Your Companies Registration Code
            </p>
            {/* <p className={styles.login_input_titles}>Registration Code</p> */}
            <div className={styles.email_input}>
              <img
                className={styles.auth_input_icon}
                alt="email"
                src="/registration-code.png"
              ></img>
              <input
                onChange={(event) => setEmail(event.target.value)}
                className={styles.input}
              ></input>
            </div>

            {error && <p className={styles.error_text}>{error}</p>}
            {canContinue ? (
              <div onClick={handleSubmit} className={styles.page_button}>
                {/* <CircularLoader/> */}
                <p className={styles.join_button_text}>Register</p>
              </div>
            ) : (
              <div className={styles.page_button_inactive}>
                <p className={styles.join_button_text}>Register</p>
              </div>
            )}
          </div>
        </div>
        {/* {showSidebar && <SignUpComponentSidebar />} */}
      </div>
    </div>
  );
};

export default EnterCompanyCode;
